import { mapMutations, mapGetters } from "vuex"
import axios from "axios"
import { generateApiUrl } from "@/utils/helpers"

export default {
  data() {
    return {
      editedSearchString: null,
      appliedLocation: null,
    }
  },
  mounted() {
    this.editedSearchString = this.searchString
  },
  computed: {
    ...mapGetters({
      selectedAccount: "accounts/getSelectedAccount",
      selectedLocation: "locations/getSelectedLocation",
    }),
    searchString() {
      var searchString = ""
      if (this.appliedLocation !== null) {
        searchString = `${ this.selectedAccount.name.replace("&", " ") } ${ this.appliedLocation.address.street_1.replace("&", " ") }, ${ this.appliedLocation.address.city.replace("&", " ") }, ${ this.appliedLocation.address.state_province.replace("&", " ") } ${ this.appliedLocation.address.postal }`
      } else if (this.selectedLocation && this.selectedLocation.address && this.selectedLocation.address.street_1) {
        searchString = `${ this.selectedAccount.name.replace("&", " ") } ${ this.selectedLocation.address.street_1.replace("&", " ") }, ${ this.selectedLocation.address.city.replace("&", " ") }, ${ this.selectedLocation.address.state_province.replace("&", " ") } ${ this.selectedLocation.address.postal }`
      }
      return searchString
    },
  },
  methods: {
    generateGoogleReviewUrl() {
      return new Promise(async (resolve, reject) => {
        try {
          const endpoint = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json"
          const data = {
            input: this.editedSearchString !== this.searchString ? this.editedSearchString : this.searchString,
            inputtype: "textquery",
            locationbias: `point:${ this.selectedLocation.address.lat },${ this.selectedLocation.address.lon }`,
          }
          const url = `${ endpoint }?input=${ data["input"] }&inputtype=${ data["inputtype"] }&locationbias=${ data["locationbias"] }`
          let reviewUrl = generateApiUrl("google-review/url")
          let { data: res } = await axios.post(reviewUrl, { url: url })
          let googlePlace = res.data
          if (googlePlace.status === "OK" && googlePlace.candidates && googlePlace.candidates.length > 0) {
            let smsReviewLinkUrl = `https://search.google.com/local/writereview?placeid=${ googlePlace.candidates[0].place_id }`

            return resolve(smsReviewLinkUrl)
          } else {
            console.error("No results found for Google review link.")
            return resolve("")
          }
        } catch (e) {
          console.error("Error generateGoogleReviewUrl: ", e)
          this.toastError("Error generating google review url")
          return reject("Could not get url")
        }
      })
    },
  },
}
